<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3" md="3">
        <v-btn
          v-if="is_support_chat_available && !isSmallClient"
          color="primary"
          block
          class="text-none text-body-1 font-weight-bold pl-4 pr-5 mb-4"
          large
          @click="initChat"
        >
          <v-icon left>mdi-help-circle-outline</v-icon>
          {{ $ml.get("need_help_question") }}
        </v-btn>
        <v-card outlined :loading="loadingSelectedGroup">
          <div
            class=""
            :class="
              $vuetify.breakpoint.lgAndUp
                ? 'd-flex align-center px-2'
                : 'px-4 pt-5'
            "
          >
            <v-card-text :class="$vuetify.breakpoint.mdAndDown ? 'px-0' : ''">
              <v-card-title class="font-weight-bold pa-0">
                {{ name }}
              </v-card-title>
              <div
                :class="`${
                  status
                    ? 'green--text text--lighten-2'
                    : 'red--text text--lighten-2'
                } text-uppercase text-body-2 font-weight-bold my-0`"
              >
                {{ status ? $ml.get("active") : $ml.get("disabled") }}
              </div>
              <!-- <v-card-subtitle class="caption px-0 py-2 ma-0">
                Último login em 13 de jan. de 2022
              </v-card-subtitle> -->
            </v-card-text>
          </div>
          <div
            class="pb-4 ma-0 yellow lighten-5 text-body-2"
            v-if="definedActivationPeriod"
          >
            <v-divider></v-divider>
            <strong class="d-flex align-center py-2 px-4">
              {{ $ml.get("activation_period") }}
            </strong>
            <span class="px-4">
              {{ $ml.get("from") }}
              <strong>
                {{ formatDateUTC(selectedGroup.start_of_date_use) }}</strong
              >
              {{ $ml.get("until") }}
              <strong>
                {{ formatDateUTC(selectedGroup.end_of_date_use) }}</strong
              >
            </span>
          </div>
          <v-divider />
          <v-list class="py-0">
            <v-list-item-group color="grey darken-1">
              <v-list-item
                v-for="(item, i) in groupOptions"
                :key="i"
                class="text-uppercase font-weight-medium"
                link
                :disabled="item.disabled"
                @click="execute(item.action)"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon v-text="item.icon" color="grey darken-1"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="`${
                      $vuetify.theme.dark ? '' : 'grey--text text--darken-1'
                    }`"
                  >
                    <span v-for="(key, i) in item.title_keys" :key="i">
                      {{ `${$ml.get(key)} ` }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- ÁREA DAS CONFIGURAÇÕES (COLUNA 2) -->
      <v-col cols="12" lg="9" md="9" v-if="!editGroupMode">
        <!-- CONFIGURAÇÕES DE LOGIN E LOGOUT -->
        <v-card
          outlined
          class="mb-4 px-2 hover-card pointer"
          :loading="loadingSelectedGroup"
        >
          <v-card-title
            class="d-flex align-center"
            @click="execute('logout_settings')"
          >
            <span class="link mr-1">{{ $ml.get("login_logout_access") }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon elevation="0">
                  <v-icon v-text="'mdi-pencil'" />
                </v-btn>
              </template>
              <span>{{ $ml.get("edit") }}</span>
            </v-tooltip>
            <v-spacer />
          </v-card-title>
          <v-row class="px-3 mb-2" v-if="show.logout_settings">
            <v-col
              v-for="(item, i) in logoutSettings"
              :key="i"
              @click="execute('logout_settings')"
              class="link"
            >
              <div class="d-flex align-start text-body-1 font-weight-medium">
                <div>
                  <v-icon
                    v-text="item.show ? 'mdi-check-circle' : 'mdi-close-circle'"
                    :color="item.show ? 'success' : 'error'"
                    size="22"
                    class="mr-2"
                    :disabled="item.disabled"
                  />
                  {{ $ml.get(item.title_key) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- CARD DE REGRAS POR IP -->
        <v-card
          outlined
          class="mb-4 px-2 hover-card pointer"
          :loading="loadingSelectedGroup"
        >
          <v-card-title @click="execute('access_settings')">
            <span class="link">{{ $ml.get("access_settings") }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon elevation="0">
                  <v-icon v-text="'mdi-pencil'" />
                </v-btn>
              </template>
              <span>{{ $ml.get("edit") }}</span>
            </v-tooltip>
          </v-card-title>

          <v-row class="px-4 mb-2">
            <v-col class="show-edit">
              <div
                class="font-weight-medium body-1"
                @click="execute('access_settings')"
              >
                {{ $ml.get("allowed_ip_addresses") }}
              </div>
              <div
                class="font-weight-regular body-2 link show-edit mt-1"
                @click="execute('access_settings')"
              >
                <span v-if="listOfIPs.length > 0">
                  <v-chip
                    small
                    class="mr-2 link"
                    v-for="IP in listOfIPs"
                    :key="IP"
                    v-text="IP"
                  />
                </span>
                <span v-else> - </span>
              </div>
            </v-col>
            <v-col class="show-edit">
              <div
                class="font-weight-medium body-1"
                @click="execute('access_settings')"
              >
                {{ $ml.get("allowed_browsers") }}
              </div>
              <div
                class="font-weight-regular body-2 link show-edit mt-1"
                @click="execute('access_settings')"
              >
                <span v-if="allowedBrowsers.length">
                  <v-chip
                    small
                    class="mr-2 link"
                    v-for="browser in allowedBrowsers"
                    :key="browser"
                    v-text="browser"
                  />
                </span>
                <span v-else> - </span>
              </div>
            </v-col>
            <v-col class="show-edit">
              <div
                class="font-weight-medium body-1"
                @click="execute('access_settings')"
              >
                {{ $ml.get("allowed_os") }}
              </div>
              <div
                class="font-weight-regular body-2 link show-edit mt-1"
                @click="execute('access_settings')"
              >
                <span v-if="allowedOs.length">
                  <v-chip
                    small
                    class="mr-2 link"
                    v-for="os in allowedOs"
                    :key="os"
                    v-text="os"
                  />
                </span>
                <span v-else> - </span>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- CARD DE MEMBROS DO GRUPO -->
        <v-card
          outlined
          class="mb-4 px-2 hover-card pointer"
          :loading="loadingSelectedGroup"
        >
          <v-card-title @click="editGroupParticipants = true">
            <span class="link mr-1">{{ $ml.get("participants") }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon elevation="0">
                  <v-icon v-text="'mdi-pencil'" />
                </v-btn>
              </template>
              <span>{{ $ml.get("edit") }}</span>
            </v-tooltip>
          </v-card-title>

          <v-row class="px-4 mb-2">
            <v-col v-for="(item, i) in participantsInfo" :key="i">
              <div class="font-weight-medium body-1">
                {{ $ml.get(item.title_key) }}
              </div>
              <div
                class="font-weight-regular body-2 text--lighten-3 show-edit"
                @click="editGroupParticipants = true"
              >
                {{ `${item.description} ${$ml.get("users").toLowerCase()}` }}
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- PERMISSÕES DE ACESSO -->
        <v-card outlined class="mb-4 px-2" :loading="loadingSelectedGroup">
          <v-card-title>
            <div
              class="pointer d-flex align-center"
              @click="editGroupRules = true"
            >
              <span class="link mr-1">{{ $ml.get("time_settings") }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" left dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $ml.get("time_settings_info") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon elevation="0">
                    <v-icon v-text="'mdi-pencil'" />
                  </v-btn>
                </template>
                <span>{{ $ml.get("edit") }}</span>
              </v-tooltip>
            </div>
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

            <v-select
              class="mb-1 mr-4"
              v-model="selectedViewType"
              :items="viewRules"
              dense
              outlined
              :multiple="false"
              style="max-width: 200px; height: 35px"
            >
              <template v-slot:selection="{ item }">
                {{ $ml.get(item.text) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $ml.get(item.text) }}
              </template>
              <template v-slot:prepend-inner>
                <v-icon
                  v-text="
                    selectedViewType === 'week'
                      ? 'mdi-calendar-week'
                      : 'mdi-calendar-month'
                  "
                  class="mr-2"
                />
              </template>
            </v-select>
            <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
          </v-card-title>

          <v-card-text class="mt-4 px-1" height="350">
            <v-row>
              <!-- FERIADOS E DATAS ESPECIAIS -->
              <v-col cols="12" sm="2" class="pr-0 pl-6">
                <v-menu offset-y max-width="300" :nudge-top="-5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="2"
                      rounded
                      large
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="text-none body-1 font-weight-bold"
                    >
                      <v-icon left v-text="'mdi-plus'" size="28" />
                      {{ $ml.get("create") }}
                      <v-icon right v-text="'mdi-menu-down'" />
                    </v-btn>
                  </template>
                  <v-card class="py-0">
                    <v-list dense>
                      <v-list-item
                        class="text-subtitle-2"
                        link
                        @click="execute('special_date')"
                      >
                        {{ `${$ml.get("holidays_and_special_dates")}` }}
                      </v-list-item>
                      <v-list-item
                        class="text-subtitle-2"
                        link
                        @click="editGroupRules = true"
                      >
                        {{ `${$ml.get("access_rule")}` }}
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>

                <v-expansion-panels
                  hover
                  flat
                  v-model="expanded"
                  class="mt-4 py-0"
                >
                  <v-expansion-panel class="py-0 my-0">
                    <v-expansion-panel-header
                      style="height: 20px"
                      class="font-weight-medium pa-0 grey--text text--darken-2"
                    >
                      {{
                        `${$ml.get("holiday")}/${$ml
                          .get("special_date")
                          .toLowerCase()}`
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      :class="`
                        py-0 mr-2 mt-0 calendar-list-header
                      `"
                    >
                      <div
                        class="font-weight-regular body-2"
                        v-if="nextSpecialDates.length"
                      >
                        <v-list dense class="mt-0 py-0">
                          <v-list-item
                            v-for="specialDate in nextSpecialDates"
                            :key="specialDate"
                            class="my-0 px-1 py-0"
                          >
                            <v-list-item-title class="py-0 font-weight-regular">
                              {{ specialDate }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </div>
                      <v-btn
                        v-if="nextSpecialDates.length"
                        text
                        outlined
                        color="accent"
                        class="mt-2 px-4 mx-auto"
                        small
                        @click="execute('special_date')"
                      >
                        <v-icon left v-text="'mdi-calendar'" />
                        {{ $ml.get("view_more") }}
                      </v-btn>
                      <span v-else> {{ $ml.get("no_date") }} </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <!-- CALENDARIO -->
              <v-col class="pl-0">
                <v-calendar
                  :first-interval="0"
                  :interval-minutes="60"
                  :interval-count="24"
                  ref="calendar"
                  :now="today"
                  :value="today"
                  :events="events"
                  color="primary"
                  :type="selectedViewType"
                  style="border: 0px; height: 600px"
                  class="overflow-y-auto calendar px-2"
                  @click:time="addWeekEvent"
                  @click:date="addMonthEvent"
                  @click:day="addMonthEvent"
                >
                  <template v-slot:event="{ event }">
                    <v-menu
                      v-if="event.type === 'temporary'"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-width="300"
                      offset-x
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="font-weight-medium mx-1 text-center">
                            {{ $ml.get("no_title") }}
                          </div>
                          <div class="mx-1 small-text text-center">
                            {{ formatHourPeriod(event.start, event.end) }}
                          </div>
                        </div>
                      </template>
                      <AddOrEditHourRuleInCalendar
                        :groupKey="groupKey"
                        :date="selectedHour.date"
                        :selected="selectedHour"
                        @close="menu = false"
                      />
                    </v-menu>

                    <div
                      v-else
                      @click.stop="editRuleOrSpecialDate(event.type)"
                      class="fill-height"
                    >
                      <div class="font-weight-medium mx-1 text-center">
                        {{
                          event.type === "business_hours"
                            ? $ml.get("business_hours")
                            : `${$ml.get("holiday")}/${$ml
                                .get("special_date")
                                .toLowerCase()}`
                        }}
                      </div>
                      <div class="mx-1 small-text text-center">
                        {{ formatHourPeriod(event.start, event.end) }}
                      </div>
                    </div>
                  </template>
                </v-calendar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!--  CARD DE POLÍTICA DE SENHA -->
        <v-card
          outlined
          class="mb-4 px-2 hover-card pointer"
          :loading="loadingSelectedGroup"
        >
          <v-card-title @click="execute('password_policy')">
            <span class="mr-1">{{ $ml.get("password_policy") }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon elevation="0">
                  <v-icon v-text="'mdi-pencil'" />
                </v-btn>
              </template>
              <span>{{ $ml.get("edit") }}</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text @click="execute('password_policy')">
            <div class="font-weight-medium body-1">
              <span v-if="hasPasswordPolicy" class="d-flex align-center link">
                <v-icon
                  v-text="'mdi-check-circle'"
                  color="success"
                  size="20"
                  class="mr-2"
                />
                {{
                  `
                  ${$ml.get(
                    "has_to_change_password_by_period"
                  )} ${daysToExpirePassword}
                   ${
                     daysToExpirePassword > 1 ? $ml.get("days") : $ml.get("day")
                   }
                  `
                }}
              </span>
              <span v-else class="d-flex align-center">
                <v-icon
                  v-text="'mdi-close-circle'"
                  color="error"
                  size="20"
                  class="mr-2"
                />
                {{ $ml.get("there_are_no_settings") }}
              </span>
            </div>
            <div v-if="dateToExpirePassword" class="mx-7">
              {{
                `${$ml.get(
                  "next_group_password_change"
                )} ${dateToExpirePassword}`
              }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="9" md="9" sm="12" v-else>
        <EditGroupMembers
          :show="editGroupParticipants"
          @close="editGroupParticipants = false"
        />

        <EditGroupRules
          v-if="!editGroupParticipants"
          :show="editGroupRules"
          @close="editGroupRules = false"
        />
      </v-col>
    </v-row>
    <!-- EDIÇÃO BÁSICAS DO GRUPO -->
    <EditGroupInfo
      :show="showEditDialog"
      :showConfigDialog="showDialogFullScreen"
      :edit="currentAction"
      :groupKey="groupKey"
      :daysToExpire="daysToExpirePassword"
      :listOfIPs="listOfIPs"
      :listOfSpecialDates="listOfSpecialDates"
      :activationPeriod="activationPeriod"
      @close="(showEditDialog = false), (showDialogFullScreen = false)"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AddOrEditHourRuleInCalendar from "@/components/groups/AddOrEditHourRuleInCalendar.vue";
import EditGroupInfo from "@/components/groups/EditGroupInfo.vue";
import EditGroupMembers from "@/components/groups/EditGroupMembers.vue";
import EditGroupRules from "@/components/groups/EditGroupRules.vue";
import {
  formatHourPeriod,
  formatDateForDatabase,
  formatStringHour,
  formatDateFullMonth,
  addHour,
  formatDate,
  formatDateDefault,
  getPreviousAndNextMonth,
  formatDateUTC,
} from "@/helpers/utils";
import { billingAddPlan } from "@/helpers/links";

export default {
  components: {
    AddOrEditHourRuleInCalendar,
    EditGroupInfo,
    EditGroupMembers,
    EditGroupRules,
  },
  data() {
    return {
      expanded: 0,
      viewRules: [
        { text: "this_week", value: "week" },
        { text: "this_month", value: "month" },
        { text: "this_day", value: "day" },
      ],
      selectedViewType: "week",
      selectedHour: {
        date: "",
        hour: "",
        week: [],
      },
      showEdit: false,
      menu: false,
      customGroupEvents: [],
      action: "",
      /* Para edições simples */
      show: {
        logout_settings: true,
        password_settings: true,
      },
      billingAddPlan,
      screenWidth: window.innerWidth,
      showDialogFullScreen: false,
    };
  },
  watch: {
    isSmallScreen() {
      if (this.isSmallScreen) {
        this.selectedViewType = "day";
      }
      if (!this.isSmallScreen) {
        this.selectedViewType = "week";
      }
    },

    menu() {
      if (!this.menu) {
        this.customGroupEvents = [];
      }
    },

    "selectedGroup.advanced_auto_logout"() {
      if (this.selectedGroup.advanced_auto_logout) {
        this.selectedGroup.auto_logout = false;
      }
    },

    async "$route.params.key"() {
      this.setSelectedGroupWithKey(this.$route.params.key);
      let group = this.selectedGroup;
      if (Object.keys(group).length == 0) {
        this.$router.push("/groups");
      }
    },

    groups() {
      this.setSelectedGroupWithKey(this.$route.params.key);
      let group = this.selectedGroup;
      if (Object.keys(group).length == 0) {
        this.$router.push("/groups");
      }
    },
  },
  computed: {
    ...mapGetters([
      "selectedGroup",
      "loadingSelectedGroup",
      "editGroupMode",
      "editRules",
      "editParticipants",
      "hasPontoMaisIntegration",
      "pontoMaisToken",
      "groups",
      "is_support_chat_available",
      "isSmallClient",
    ]),
    pontoMaisAllowed() {
      return this.hasPontoMaisIntegration && this.pontoMaisToken;
    },

    editGroupParticipants: {
      get() {
        return this.editParticipants;
      },
      set(new_value) {
        this.setEditGroupMode({ key: "participants", value: new_value });
      },
    },

    editGroupRules: {
      get() {
        return this.editRules;
      },
      set(new_value) {
        this.setEditGroupMode({ key: "access_permissions", value: new_value });
      },
    },

    groupOptions() {
      return [
        {
          title_keys: ["rename", "group"],
          icon: "mdi-pencil",
          action: "group_name",
          show: true,
        },
        {
          title_keys: ["edit_login_logout_access"],
          icon: "mdi-login",
          action: "logout_settings",
          show: true,
        },
        {
          title_keys: ["manage_participants"],
          icon: "mdi-account-multiple",
          action: "manage_participants",
          show: true,
        },
        {
          title_keys: ["edit_operation_hours"],
          icon: "mdi-clock",
          action: "time_settings",
          show: true,
        },
        {
          title_keys: ["activation_period"],
          icon: "mdi-calendar",
          action: "activation_period",
          show: !this.definedActivationPeriod,
        },
        {
          title_keys: ["edit_activation_period"],
          icon: "mdi-calendar",
          action: "activation_period",
          show: this.definedActivationPeriod,
        },
        {
          title_keys: ["remove_activation_period"],
          icon: "mdi-calendar-remove",
          action: "remove_activation_period",
          show: this.definedActivationPeriod,
        },
        {
          title_keys: ["edit_password_policy"],
          icon: "mdi-lock",
          action: "password_policy",
          show: true,
        },
        {
          title_keys: ["disable", "group"],
          icon: "mdi-sync-off",
          action: "disable_group",
          show: this.selectedGroup.is_active,
        },
        {
          title_keys: ["clone", "group"],
          icon: "mdi-content-copy",
          action: "clone_group",
          show: true,
        },
        {
          title_keys: ["activate", "group"],
          icon: "mdi-sync",
          action: "activate_group",
          show: !this.selectedGroup.is_active,
        },
        {
          title_keys: ["delete", "group"],
          icon: "mdi-delete",
          action: "delete_group",
          show: !this.selectedGroup.is_active,
        },
        {
          title_keys: ["request_password_change"],
          icon: "mdi-lock-reset",
          action: "request_password_change",
          show: true,
        },
        {
          title_keys: ["request_logout"],
          icon: "mdi-logout", //change icon
          action: "request_logout",
          show: true,
        },
      ].filter((option) => option.show);
    },

    showEditDialog: {
      get() {
        return this.showEdit;
      },
      set(new_value) {
        this.showEdit = new_value;
      },
    },

    daysToExpirePassword() {
      return this.selectedGroup.days_period_to_expire_password || null;
    },

    dateToExpirePassword() {
      return this.selectedGroup.date_to_expire_password
        ? formatDateFullMonth(this.selectedGroup.date_to_expire_password)
        : null;
    },

    hasPasswordPolicy() {
      return this.selectedGroup.days_period_to_expire_password;
    },

    groupKey() {
      return this.selectedGroup.key;
    },

    name() {
      return this.selectedGroup.name;
    },

    status() {
      return this.selectedGroup.is_active;
    },

    rules() {
      return this.selectedGroup.rules;
    },

    participantsSize() {
      return this.selectedGroup.users ? this.selectedGroup.users.length : 0;
    },

    participantsAdmins() {
      return this.selectedGroup.users
        ? this.selectedGroup.users.filter((user) => user.is_admin).length
        : 0;
    },

    participantsInfo() {
      return [
        {
          title_key: "direct_participants",
          description: this.participantsSize,
        },
        {
          title_key: "google_admin",
          description: this.participantsAdmins,
        },
      ];
    },

    nextSpecialDates() {
      return this.listOfSpecialDates.length >= 5
        ? this.listOfSpecialDates.slice(0, 5)
        : this.listOfSpecialDates;
    },

    listOfSpecialDates() {
      return this.selectedGroup.list_special_dates
        ? this.selectedGroup.list_special_dates
        : [];
    },

    listOfIPs() {
      return this.selectedGroup.list_ip || [];
    },

    activationPeriod() {
      if (
        this.selectedGroup.start_of_date_use &&
        this.selectedGroup.end_of_date_use
      ) {
        return [
          formatDateDefault(this.selectedGroup.start_of_date_use),
          formatDateDefault(this.selectedGroup.end_of_date_use),
        ];
      }

      return [];
    },

    allowedBrowsers() {
      return this.selectedGroup.allowed_browsers || [];
    },

    allowedOs() {
      return this.selectedGroup.allowed_os || [];
    },

    today() {
      return formatDateForDatabase(new Date());
    },

    hourRules() {
      return this.selectedGroup.rules ? this.selectedGroup.rules : [];
    },

    isWeekView() {
      return this.selectedViewType === "week";
    },

    specialDates() {
      return this.listOfSpecialDates.reduce((acc, date) => {
        const _date = formatDateForDatabase(date, "DD/MM/YYYY");

        if (_date) {
          acc.push({
            type: "special_date",
            start: _date,
            end: _date,
            color: "info",
          });
        }
        return acc;
      }, []);
    },

    loginEvents() {
      let dates = getPreviousAndNextMonth();

      return dates.flatMap((item) => {
        let search = this.hourRules.filter(
          (hourRule) => hourRule.day === item.day_number
        );

        let dateToSearch = formatDate(item.date);
        let isSpecialDate = this.listOfSpecialDates.includes(dateToSearch);

        return search.reduce((acc, rule) => {
          if (!isSpecialDate) {
            acc.push({
              type: "business_hours",
              start: `${item.date} ${formatStringHour(rule.start_hour)}`,
              end: `${item.date} ${formatStringHour(rule.end_hour)}`,
              color: "success",
            });
          }
          return acc;
        }, []);
      });
    },

    events() {
      let groupEvents = [];
      Array.prototype.push.apply(groupEvents, this.loginEvents);
      Array.prototype.push.apply(groupEvents, this.specialDates);
      Array.prototype.push.apply(groupEvents, this.customGroupEvents);
      return groupEvents;
    },

    hasLogout() {
      return (
        this.selectedGroup.auto_logout ||
        this.selectedGroup.advanced_auto_logout
      );
    },

    currentAction: {
      get() {
        return this.action;
      },
      set(new_value) {
        this.action = new_value;
      },
    },

    logoutSettings() {
      return [
        {
          title_key: "advanced_logout_rule",
          show: this.selectedGroup.advanced_auto_logout,
        },
        {
          title_key: "logout_rule",
          show: this.selectedGroup.auto_logout,
        },
        {
          title_key: "pontomais_login_message",
          show: this.selectedGroup.pontomais_login_rule,
          disabled: !this.pontoMaisAllowed,
        },
        {
          title_key: "pontomais_auto_logout_label",
          show:
            (this.selectedGroup.pontomais_auto_logout_rules || []).length > 0,
          disabled: !this.pontoMaisAllowed,
        },
      ];
    },

    definedActivationPeriod() {
      return this.selectedGroup.defined_activation_period;
    },
    isSmallScreen() {
      return this.screenWidth < 512;
    },
  },
  methods: {
    ...mapActions([
      "checkPontoMaisIntegration",
      "getPontoMaisToken",
      "getHubspotToken",
    ]),
    ...mapMutations(["setEditGroupMode", "setSelectedGroupWithKey"]),

    getPreviousAndNextMonth,

    formatHourPeriod,

    formatDateFullMonth,

    formatDateUTC,

    initChat() {
      this.getHubspotToken();
    },

    editRuleOrSpecialDate(type) {
      if (type === "business_hours") {
        this.setRules();
      } else {
        this.execute("special_date");
      }
    },

    setLogoutSettings() {
      this.show.logout_settings = !this.show.logout_settings;
    },

    setParticipants() {
      this.setEditGroupMode({ key: "participants", value: true });
    },

    setRules() {
      this.setEditGroupMode({ key: "access_permissions", value: true });
    },

    setPasswordSettings() {
      this.show.password_settings = !this.show.password_settings;
    },

    execute(action) {
      if (action === "time_settings") {
        this.editGroupRules = true;
      } else {
        this.currentAction = action;
        this.show[action] = true;
        if (action === "manage_participants") {
          this.editGroupParticipants = true;
        } else if (action === "logout_settings") {
          this.showDialogFullScreen = true;
        } else if (
          action === "activate_group" &&
          this.definedActivationPeriod
        ) {
          this.show[action] = true;
          this.showEditDialog = true;
        } else if (
          ![
            "activate_group",
            "manage_participants",
            "remove_activation_period",
          ].includes(action)
        ) {
          this.showEditDialog = true;
        }
      }
    },

    addWeekEvent(event) {
      const date = `${event.date} ${event.time}`;
      this.customGroupEvents.push({
        type: "temporary",
        start: date,
        end: addHour(date),
        color: "warning",
      });
      this.selectedHour.date = event.date;
      this.selectedHour.full_date = date;
      this.selectedHour.hour = event.hour;
      this.selectedHour.start_hour = event.time;
      this.selectedHour.end_hour = addHour(date);
      this.selectedHour.week = event.week;
      this.selectedHour.weekday = event.weekday + 1;
      this.menu = true;
    },

    addMonthEvent(event) {
      if (this.selectedViewType === "month") {
        const date = `${event.date}`;
        this.customGroupEvents.push({
          type: "temporary",
          start: `${date} 00:00`,
          end: `${date} 23:59`,
          color: "warning",
        });
        this.selectedHour.date = event.date;
        this.selectedHour.full_date = date;
        this.selectedHour.hour = event.hour;
        this.selectedHour.start_hour = "00:00";
        this.selectedHour.end_hour = "23:59";
        this.selectedHour.week = event.week;
        this.selectedHour.weekday = event.weekday + 1;
        this.menu = true;
      }
    },

    editRule() {
      this.setEditGroupMode({ key: "access_permissions", value: true });
    },

    goToIntegrations() {
      this.$router.push("/integrations");
    },

    openLink() {
      window.open(this.billingAddPlan, "_blank");
    },

    handleResize() {
      this.screenWidth = window.innerWidth;
    },

    startLoadGroup() {
      this.setSelectedGroupWithKey(this.$route.params.key);
    },
  },
  beforeMount() {
    if (this.hasPontoMaisIntegration === undefined) {
      this.checkPontoMaisIntegration();
    }
    if (this.pontoMaisToken === undefined) {
      this.getPontoMaisToken();
    }
    this.startLoadGroup();
  },
  mounted() {
    this.selectedViewType = this.isSmallScreen ? "day" : "week";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style>
.small-text {
  font-size: 10px !important;
}

.v-calendar-daily_head-day-label button,
.v-calendar-daily .v-calendar-daily_head-day,
.v-calendar-daily_head-day-label {
  cursor: auto;
}

.v-calendar-daily__day-interval,
.v-calendar-weekly__day {
  cursor: pointer !important;
}
.calendar ::-webkit-scrollbar {
  width: 7px;
}

.calendar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.calendar ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px !important;
}
.show-edit > i,
.show-edit > button {
  visibility: hidden;
}
.show-edit:hover > i,
.show-edit:hover > button {
  visibility: visible;
}

.hover-card:hover {
  background: var(--v-background_hover-base);
}

.v-expansion-panel-content__wrap {
  padding: 0px 0px 16px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px !important;
}
</style>
